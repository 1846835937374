import React from 'react';
import './App.css';
import Router from 'router'
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/components/effect-coverflow/effect-coverflow.scss';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y,Autoplay,EffectCoverflow } from 'swiper';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y,Autoplay,EffectCoverflow]);

function App() {
  return <Router/>
}

export default App;
