import React from 'react';
import {Route,Switch} from 'react-router-dom';

import Index from 'view/index'
import Communication from 'view/communication'
import CompanyInfo from 'view/companyInfo'
import AsboutUs from 'view/aboutUs'
import Combine from 'view/combine'
import Whale from 'view/whale'
import newDetails from 'view/newDetails'


export default () => (
    <Switch>
      <Route exact path="/" component={Index}></Route>
      <Route exact path="/communication" component={Communication}></Route>
      <Route exact path="/combine" component={Combine}></Route>
      <Route exact path="/whale" component={Whale}></Route>
      <Route exact path="/companyInfo" component={CompanyInfo}></Route>
      <Route exact path="/aboutUs" component={AsboutUs}></Route>
      <Route exact path="/newDetails" component={newDetails}></Route>
    </Switch>
);
