import Ajax from "./request";

function connect(params) {
  let url = `?`;
  for (let key in params) {
    url = `${url}${key}=${params[key]}&`;
  }
  return url;
}

export default {
  Journalism:{
    getAllNewsList(params) {
      // 获取新闻列表
      return Ajax.ajaxs("/news/getAllNewsList", params);
    },
    getByNewsId(params) {
      // 获取新闻详情
      return Ajax.ajaxs("/news/getByNewsId", params);
    }
  },
  login: {
    signIn(params) {
      // 登录
      return Ajax.ajaxs("/user/login", params);
    },
    signUp(params) {
      // 注册
      return Ajax.ajaxs("/user/register", params);
    },
    getBackPassword(params) {
      // 找回密码
      return Ajax.ajaxs("/user/reset/password", params);
    },
    getCodeImgUrl() {
      // 图片验证码
      return Ajax.ajaxs("/verifyCode", {}, "GET");
    },
    getPhoneCode(params) {
      //短信验证码
      return Ajax.ajaxs("/sendMobileCode", params);
    },
    logOut() {
      //退出登录
      return Ajax.ajaxs("/user/logout", {});
    },
    getUserInfo(key = '') {
      //获取用户信息
      return Ajax.ajaxs(`/user/getUserInfo?sessionKey=${key}`, {}, "GET");
    },
  }
};
