import React from 'react'
import { Main,Content} from './style'

let data = [
    {
      className: 'address',
      title: '公司地址',
      text: '深圳市南山区科伟路16号东方科技大厦1101',
    },
    {
      className: 'phone',
      title: '联系电话',
      text: '400-8888-513',
    },
    {
      className: 'email',
      title: '企业邮箱',
      text: 'service01@513.com',
    },
  ]

export default () => {
    return <Main>
        <Content>
            <div>
                <p className="en">CONNECT</p>
                <p className="zh">联系我们</p>
                <ul>
                    {
                        data.map((item,index)=>{
                            return <li key={index} className={item.className}>
                                <p>{item.title}</p>
                                <p>{item.text}</p>
                            </li>
                        })
                    }
                </ul>
            </div>
        </Content>
    </Main> 
}